import useScreenSize from "../../hooks/useScreenSize";
import breakpoints from "../../theme/antdesign";
import colors from "../../theme/thiana";
import Visual from "../../assets/logo/Visual.png";
import ThianaLogo from "../../assets/logo/LOGO_THIANA.svg";

interface Props {
  children: JSX.Element;
}
export default function Layout(props: Props) {
  let { currentScreenSize } = useScreenSize();
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
      }}
    >
      {currentScreenSize <= breakpoints.md ? null : (
        <div
          style={{
            flex: 1,
            height: "100%",
            backgroundColor: colors.thiana.grey[100],
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            maxWidth: "50%",
            gap: 20,
          }}
        >
          <div
            style={{
              height: "100%",
              width: "100%",
              backgroundImage: `url(${Visual})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div
              style={{
                marginTop: 20,
                marginLeft: 50,
                gap: 10,
              }}
            >
              <img
                src={ThianaLogo}
                alt="logo"
                width={"100px"}
                style={{ width: 100 }}
              ></img>
              <div style={{ color: "white", fontSize: 14 }}>
                L'intelligence artificielle au service des médecins
              </div>
            </div>
          </div>
        </div>
      )}
      <div style={{ flex: 1 }}>{props.children}</div>
    </div>
  );
}
