import * as React from "react";
import { useState } from "react";
import { Button, Input, message } from "antd";
import Title from "antd/es/typography/Title";
import useLogin from "../../../hooks/useLogin";
import useMessage from "antd/es/message/useMessage";

interface Props {
  setCurrentForm: React.Dispatch<React.SetStateAction<CurrentForm>>;
}
type CurrentForm = "Login" | "Register" | "ForgotPassword";

export default function Login(props: Props) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  let { login } = useLogin();
  const [messageApi, contextHolder] = message.useMessage();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        paddingRight: "10%",
        paddingLeft: "10%",
      }}
    >
      {contextHolder}
      <div>
        <Title level={1}>Connexion</Title>
        <Input
          onKeyDown={(e) => {
            if (e.key === "Enter" && email && password) login(email, password);
            else if (e.key === "Enter")
              messageApi.error("Saisir un email et un mot de passe");
          }}
          data-cy={"action-email"}
          onChange={(e) => setEmail(e.target.value)}
          id="email"
          placeholder="Adresse email"
          name="email"
          autoFocus
          style={{
            marginTop: 5,
            marginBottom: 5,
            width: "100%",
          }}
        />
        <Input.Password
          onKeyDown={(e) => {
            if (e.key === "Enter" && email && password) login(email, password);
            else if (e.key === "Enter")
              messageApi.error("Saisir un email et un mot de passe");
          }}
          data-cy={"action-password"}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Mot de passe"
          type="password"
          style={{
            marginTop: 5,
            marginBottom: 5,
            width: "100%",
          }}
        />
        <Button
          type="primary"
          onClick={() => login(email, password)}
          htmlType="submit"
          style={{ width: "100%", marginTop: 10 }}
        >
          Se connecter
        </Button>
      </div>
      <div style={{ display: "flex", justifyContent: "center", marginTop: 10 }}>
        <Button
          type={"text"}
          onClick={() => props.setCurrentForm("ForgotPassword")}
        >
          Mot de passe oublié ?
        </Button>
        <Button type={"text"} onClick={() => props.setCurrentForm("Register")}>
          Pas encore inscrit ?
        </Button>
      </div>
    </div>
  );
}
