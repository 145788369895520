import { useNavigate } from "react-router-dom";
import { hasToken, isActivate, isProfessionalCreated } from "../utils/security";
import { useEffect } from "react";

const Protected = (props: any) => {
  let navigate = useNavigate();
  useEffect(() => {
    if (hasToken()) {
      if (isActivate()) {
        if (isProfessionalCreated()) {
          navigate("/choose-your-app");
        } else navigate("/informations");
      } else navigate("/activate");
    } else navigate("/");
  }, [navigate]);

  return props.children;
};
export default Protected;
