import * as React from "react";
import { useEffect, useState } from "react";
import colors from "../../../theme/thiana";
import { CheckCircleFill, XCircleFill } from "react-bootstrap-icons";
import { Button, Checkbox, Col, Input, Row, Typography } from "antd";
import Title from "antd/es/typography/Title";
import useRegister from "../../../hooks/useRegister";
import {
  specialCharacterRegex,
  containNumberRegex,
  oneUppercaseRegex,
  emailRegex,
} from "../../../utils/regex";

interface Props {
  setCurrentForm: React.Dispatch<React.SetStateAction<CurrentForm>>;
}
type CurrentForm = "Login" | "Register" | "ForgotPassword";

export default function Register(props: Props) {
  const [email, setEmail] = useState<string>("");
  const [acceptCGU, setAcceptCGU] = useState<boolean>(false);
  const [password, setPassword] = useState<string>("");
  const [password2, setPassword2] = useState<string>("");
  let { register } = useRegister();
  // For rules password
  const [emailValid, setEmailValid] = useState<boolean>(false);
  const [passwordValid, setPasswordValid] = useState<boolean>(false);
  const [passwordLengthValid, setPasswordLengthValid] =
    useState<boolean>(false);
  const [passwordEqualValid, setPasswordEqualValid] = useState<boolean>(false);
  const [specialCharacterValid, setSpecialCharacterValid] =
    useState<boolean>(false);
  const [containNumberValid, setContainNumberValid] = useState<boolean>(false);
  const [oneUppercaseValid, setOneUppercaseValid] = useState<boolean>(false);

  React.useEffect(() => {
    let valid = true;
    if (password.length >= 8) setPasswordLengthValid(true);
    else {
      setPasswordLengthValid(false);
      valid = false;
    }
    if (specialCharacterRegex.test(password)) setSpecialCharacterValid(true);
    else {
      setSpecialCharacterValid(false);
      valid = false;
    }
    if (containNumberRegex.test(password)) setContainNumberValid(true);
    else {
      setContainNumberValid(false);
      valid = false;
    }
    if (oneUppercaseRegex.test(password)) setOneUppercaseValid(true);
    else {
      setOneUppercaseValid(false);
      valid = false;
    }
    if (password === password2 && password !== "") setPasswordEqualValid(true);
    else {
      setPasswordEqualValid(false);
      valid = false;
    }
    if (!valid) setPasswordValid(false);
    else {
      setPasswordValid(true);
      valid = false;
    }
  }, [password, password2]);

  useEffect(() => {
    if (emailRegex.test(email)) setEmailValid(true);
    else setEmailValid(false);
  }, [email]);

  const Rules = () => {
    return (
      <Row>
        <Row>
          <Col xs={24}>
            <Typography
              style={{
                color: emailValid ? colors.thiana.success[500] : "#CBCBCB",
              }}
            >
              Email valide
              {emailValid ? (
                <CheckCircleFill
                  style={{ marginLeft: 2 }}
                  color={colors.thiana.success[500]}
                  size={10}
                />
              ) : (
                <XCircleFill
                  style={{ marginLeft: 2 }}
                  color={"#CBCBCB"}
                  size={10}
                />
              )}
            </Typography>

            <Typography
              style={{
                color: passwordValid ? colors.thiana.success[500] : "#CBCBCB",
              }}
            >
              Mot de passe valide
              {passwordValid ? (
                <CheckCircleFill
                  style={{ marginLeft: 2 }}
                  color={colors.thiana.success[500]}
                  size={10}
                />
              ) : (
                <XCircleFill
                  style={{ marginLeft: 2 }}
                  color={"#CBCBCB"}
                  size={10}
                />
              )}
            </Typography>
            <div style={{ textAlign: "start", marginLeft: 20 }}>
              <Typography
                style={{
                  color: passwordLengthValid
                    ? colors.thiana.success[500]
                    : "#CBCBCB",
                }}
              >
                &#x2022; au moins 8 caractères
              </Typography>
              <Typography
                style={{
                  color: oneUppercaseValid
                    ? colors.thiana.success[500]
                    : "#CBCBCB",
                }}
              >
                &#x2022; au moins 1 majuscule
              </Typography>
              <Typography
                style={{
                  color: specialCharacterValid
                    ? colors.thiana.success[500]
                    : "#CBCBCB",
                }}
              >
                &#x2022; au moins 1 caractère spécial
              </Typography>
              <Typography
                style={{
                  color: containNumberValid
                    ? colors.thiana.success[500]
                    : "#CBCBCB",
                }}
              >
                &#x2022; au moins 1 chiffre
              </Typography>
              <Typography
                style={{
                  color: passwordEqualValid
                    ? colors.thiana.success[500]
                    : "#CBCBCB",
                }}
              >
                &#x2022; champs identiques
              </Typography>
            </div>
          </Col>
        </Row>
      </Row>
    );
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        paddingRight: "10%",
        paddingLeft: "10%",
      }}
    >
      <div>
        <Title level={1}>Inscription</Title>
        <Input
          data-cy={"action-email"}
          placeholder="Adresse email"
          onChange={(e) => setEmail(e.target.value)}
          style={{
            marginTop: 5,
            marginBottom: 5,
            width: "100%",
          }}
        />
        <Input.Password
          data-cy={"action-password1"}
          placeholder="Mot de passe"
          onChange={(e) => setPassword(e.target.value)}
          style={{
            marginTop: 5,
            marginBottom: 5,
            width: "100%",
          }}
          type="password"
        />
        <Input.Password
          data-cy={"action-password2"}
          placeholder="Confirmation du mot de passe"
          onChange={(e) => setPassword2(e.target.value)}
          style={{
            marginTop: 5,
            marginBottom: 5,
            width: "100%",
          }}
          type="password"
        />

        <div
          style={{
            display: "flex",
            gap: 5,
            alignItems: "center",
            justifyContent: "right",
            marginTop: 5,
            marginBottom: 5,
          }}
        >
          <Checkbox
            data-cy={"action-acceptCGU"}
            checked={acceptCGU}
            onChange={(e) => setAcceptCGU(e.target.checked)}
          />
          <div style={{ fontSize: 12 }}>
            En vous inscrivant, vous acceptez les{" "}
            <a
              href="https://www.thiana.fr/cgu/conditions-generales-utilisation"
              target="_blank"
              style={{ color: "black" }}
              rel="noreferrer"
            >
              conditions générations d'utilisation
            </a>{" "}
            et{" "}
            <a
              href="https://www.thiana.fr/cgu/politique-de-confidentialite"
              target="_blank"
              style={{ color: "black" }}
              rel="noreferrer"
            >
              la politique de confidentialité
            </a>
            .
          </div>
        </div>
        <Button
          type="primary"
          style={{
            cursor: !emailValid || !passwordValid ? "default" : "pointer",
            width: "100%",
            marginTop: 10,
          }}
          onClick={() => register(email, password, acceptCGU)}
          htmlType={"submit"}
          disabled={!emailValid || !passwordValid || !acceptCGU}
        >
          S'inscrire
        </Button>
        <div
          style={{ display: "flex", justifyContent: "center", marginTop: 10 }}
        >
          <Button
            type="text"
            onClick={() => props.setCurrentForm("Login")}
            style={{ marginTop: 10 }}
          >
            Déjà inscrit ?
          </Button>
        </div>
      </div>
      <Row justify={"start"}>
        <Rules />
      </Row>
    </div>
  );
}
