import * as React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Col, Input, notification, Row, Typography } from "antd";
import { useEffect, useState } from "react";
import colors from "../../../theme/thiana";
import { CheckCircleFill, XCircleFill } from "react-bootstrap-icons";
import {
  emailRegex,
  specialCharacterRegex,
  containNumberRegex,
  oneUppercaseRegex,
} from "../../../utils/regex";
import { hasToken } from "../../../utils/security";
import { apiUsers } from "../../../api/api-thiana-client/Configuration";
import jwtDecode from "jwt-decode";
import { AccessToken } from "../../../types/token";

export default function Reset(props: any) {
  let navigate = useNavigate();
  let { r } = useParams();
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [password2, setPassword2] = useState<string>("");
  // For rules password
  const [emailValid, setEmailValid] = useState<boolean>(false);
  const [passwordValid, setPasswordValid] = useState<boolean>(false);
  const [passwordLengthValid, setPasswordLengthValid] =
    useState<boolean>(false);
  const [passwordEqualValid, setPasswordEqualValid] = useState<boolean>(false);
  const [specialCharacterValid, setSpecialCharacterValid] =
    useState<boolean>(false);
  const [containNumberValid, setContainNumberValid] = useState<boolean>(false);
  const [oneUppercaseValid, setOneUppercaseValid] = useState<boolean>(false);

  useEffect(() => {
    if (r) {
      if (!hasToken(r)) {
        navigate("/logout");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [r]);

  useEffect(() => {
    if (emailRegex.test(email)) setEmailValid(true);
    else setEmailValid(false);
  }, [email]);

  useEffect(() => {
    let valid = true;
    if (password.length >= 8) setPasswordLengthValid(true);
    else {
      setPasswordLengthValid(false);
      valid = false;
    }
    if (specialCharacterRegex.test(password)) setSpecialCharacterValid(true);
    else {
      setSpecialCharacterValid(false);
      valid = false;
    }
    if (containNumberRegex.test(password)) setContainNumberValid(true);
    else {
      setContainNumberValid(false);
      valid = false;
    }
    if (oneUppercaseRegex.test(password)) setOneUppercaseValid(true);
    else {
      setOneUppercaseValid(false);
      valid = false;
    }
    if (password === password2 && password !== "") setPasswordEqualValid(true);
    else {
      setPasswordEqualValid(false);
      valid = false;
    }
    if (!valid) setPasswordValid(false);
    else {
      setPasswordValid(true);
      valid = false;
    }
  }, [password, password2]);

  const resetPasswordSubmit = async () => {
    if (email && password && password2) {
      if (password === password2 && r) {
        let decodedAccessJWT: AccessToken = jwtDecode(r);
        let requestResponse = await apiUsers.userPatchRaw(
          { id: decodedAccessJWT.sub, userPartialInput: { password } },
          { headers: { Authorization: `Bearer ${r}` } }
        );
        if (requestResponse?.raw.status === 200) {
          notification.success({
            message: "Mot de passe réinitialisé !",
            description:
              "Vous allez être redirigé.e vers le formulaire de connexion...",
          });
          setTimeout(() => {
            navigate("/logout");
          }, 3000);
        }
      }
    } else {
      notification.error({
        message: "Erreur",
        description:
          "Veuillez remplir tous les champs. Le mot de passe doit contenir au moins 6 caractères et un caractète spécial.",
      });
    }
  };

  const Rules = () => {
    return (
      <Row>
        <Row>
          <Col xs={24}>
            <Typography
              style={{
                color: emailValid ? colors.thiana.success[500] : "#CBCBCB",
              }}
            >
              Email valide
              {emailValid ? (
                <CheckCircleFill
                  style={{ marginLeft: 2 }}
                  color={colors.thiana.success[500]}
                  size={10}
                />
              ) : (
                <XCircleFill
                  style={{ marginLeft: 2 }}
                  color={"#CBCBCB"}
                  size={10}
                />
              )}
            </Typography>

            <Typography
              style={{
                color: passwordValid ? colors.thiana.success[500] : "#CBCBCB",
              }}
            >
              Mot de passe valide
              {passwordValid ? (
                <CheckCircleFill
                  style={{ marginLeft: 2 }}
                  color={colors.thiana.success[500]}
                  size={10}
                />
              ) : (
                <XCircleFill
                  style={{ marginLeft: 2 }}
                  color={"#CBCBCB"}
                  size={10}
                />
              )}
            </Typography>
            <div style={{ textAlign: "start", marginLeft: 20 }}>
              <Typography
                style={{
                  color: passwordLengthValid
                    ? colors.thiana.success[500]
                    : "#CBCBCB",
                }}
              >
                &#x2022; au moins 8 caractères
              </Typography>
              <Typography
                style={{
                  color: oneUppercaseValid
                    ? colors.thiana.success[500]
                    : "#CBCBCB",
                }}
              >
                &#x2022; au moins 1 majuscule
              </Typography>
              <Typography
                style={{
                  color: specialCharacterValid
                    ? colors.thiana.success[500]
                    : "#CBCBCB",
                }}
              >
                &#x2022; au moins 1 caractère spécial
              </Typography>
              <Typography
                style={{
                  color: containNumberValid
                    ? colors.thiana.success[500]
                    : "#CBCBCB",
                }}
              >
                &#x2022; au moins 1 chiffre
              </Typography>
              <Typography
                style={{
                  color: passwordEqualValid
                    ? colors.thiana.success[500]
                    : "#CBCBCB",
                }}
              >
                &#x2022; champs identiques
              </Typography>
            </div>
          </Col>
        </Row>
      </Row>
    );
  };

  return (
    <div
      style={{
        display: "flex",
        height: "80vh",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <div>
          <Row justify={"center"}>
            <Col xs={20} sm={16} md={15} lg={15}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 10,
                  width: "100%",
                }}
              >
                <Typography>
                  Saisissez votre adresse e-mail pour réinitialiser votre mot de
                  passe. Un lien de confirmation vous sera envoyé.
                </Typography>
                <Input
                  data-cy="action-email-reset"
                  placeholder="Adresse email"
                  onChange={(e) => setEmail(e.target.value)}
                />
                <Input.Password
                  data-cy="action-password-reset"
                  placeholder="Mot de passe"
                  onChange={(e) => setPassword(e.target.value)}
                  type="password"
                />
                <Input.Password
                  data-cy="action-password2-reset"
                  placeholder="Confirmation du mot de passe"
                  onChange={(e) => setPassword2(e.target.value)}
                  type="password"
                />
                <Button
                  type={"primary"}
                  className="buttonPrimary"
                  onClick={resetPasswordSubmit}
                >
                  Modifier mon mot de passe
                </Button>
                <Rules />
                <div style={{ textAlign: "center" }}>
                  <Button
                    type="text"
                    onClick={() => navigate("/logout")}
                    style={{
                      color: colors.thiana.grey[500],
                      marginTop: 20,
                    }}
                  >
                    Déconnexion
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}
