import * as React from "react";
import { Button, Col, notification, Row, Typography } from "antd";
import { MyInput } from "../../../styled-components/common";
import Title from "antd/es/typography/Title";
import { apiAuth } from "../../../api/api-thiana-client/Configuration";

export default function ForgotPassword(props: any) {
  const [email, setEmail] = React.useState<string>("");

  const forgotPasswordSubmit = async () => {
    let response = await apiAuth.authResetPasswordSendRaw({
      resetPasswordInput: { email: email },
    });
    if (response.raw.status === 200) {
      props.setCurrentForm("Login");
      notification.success({
        message: "Email envoyé",
        description:
          "Un email pour réinitialiser votre mot de passe vient de vous être envoyé.",
      });
    }
  };

  return (
    <Row justify={"center"}>
      <Col xs={20} sm={16} md={15} lg={15}>
        <Title level={1}>Mot de passe oublié</Title>
        <Typography>
          Saisissez votre adresse e-mail pour réinitialiser votre mot de passe.
          Un lien de confirmation vous sera envoyé.
        </Typography>
        <MyInput
          data-cy={"action-email"}
          placeholder="Adresse email"
          onChange={(e) => setEmail(e.target.value)}
        />
        <Button
          type="primary"
          style={{ width: "100%" }}
          onClick={forgotPasswordSubmit}
        >
          Réinitialiser
        </Button>

        <div
          style={{ display: "flex", justifyContent: "center", marginTop: 10 }}
        >
          <Button
            type="text"
            onClick={() => props.setCurrentForm("Login")}
            style={{ marginTop: 10 }}
          >
            Retour à la connexion
          </Button>
        </div>
      </Col>
    </Row>
  );
}
