import {
  AuthApi,
  Configuration,
  ResponseContext,
  RequestContext,
  UsersApi,
  ReferentialsApi,
  StoragesApi,
  ProfessionalApi,
} from "@thiana/api-thiana-client";
import { postMiddleware } from "./PostMiddleware";
import { preMiddleware } from "./PreMiddleware";

// API configuration
const configuration = new Configuration({
  basePath: process.env.REACT_APP_URL_BACKAPP_CLIENT,
  middleware: [
    {
      // function called before the request
      pre: async (context: RequestContext) => {
        await preMiddleware(context);
      },
      // function called on the response
      post: async (context: ResponseContext) => {
        await postMiddleware(context);
      },
    },
  ],
});

// Services
export const apiAuth = new AuthApi(configuration);
export const apiUsers = new UsersApi(configuration);
export const apiProfessional = new ProfessionalApi(configuration);
export const apiReferentials = new ReferentialsApi(configuration);
export const apiStorages = new StoragesApi(configuration);
