import { ChangeEvent, useEffect, useRef, useState } from "react";
import {
  Specialities,
  SpecialitiesResponse,
  Speciality,
} from "../../../types/specialities";
import { useNavigate } from "react-router-dom";
import colors from "../../../theme/thiana";
import Title from "antd/es/typography/Title";
import Input from "antd/es/input/Input";
import DatePicker, { DatePickerProps } from "antd/es/date-picker";
import dayjs from "dayjs";
import { Avatar, Button, Select, notification } from "antd";
import { ProfessionalsResponse } from "../../../types/professionals";
import {
  UploadOutlined,
  CheckCircleFilled,
  UserOutlined,
} from "@ant-design/icons";
import useScreenSize from "../../../hooks/useScreenSize";
import breakpoints from "../../../theme/antdesign";
import { PostFileResponse } from "../../../types/files";
import Checkbox from "antd/es/checkbox/Checkbox";
import {
  apiAuth,
  apiReferentials,
  apiStorages,
  apiUsers,
} from "../../../api/api-thiana-client/Configuration";
import { AccessToken } from "../../../types/token";
import jwtDecode from "jwt-decode";
import { ProfessionalInput } from "@thiana/api-thiana-client";

const selectStyle = {
  width: "100%",
};

const titleStyle = {
  fontSize: 12,
  color: colors.thiana.grey[500],
  marginBottom: 4,
};

type OptionSpeciality = {
  label: string;
  value: string;
};

export default function Informations() {
  const [acceptVoiceUsage, setAcceptVoiceUsage] = useState<boolean>(false);
  const [avatarID, setAvatarID] = useState<string>("");
  const [avatar, setAvatar] = useState<string>("");
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [schoolCertificateID, setSchoolCertificateID] = useState<string>("");
  const [birthDate, setBirthDate] = useState<string>("");
  const [canSubmit, setCanSubmit] = useState<boolean>(false);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [specialities, setSpecialities] = useState<Specialities>([]);
  const [optionsSpecialities, setOptionsSpecialities] = useState<
    OptionSpeciality[]
  >([]);
  const [status, setStatus] = useState<string>("l2l3");
  const [otherStatut, setOtherStatut] = useState<string>("");
  const [selectedYear, setselectedYear] = useState<number>(1);
  const [selectedSpeciality, setSelectedSpeciality] = useState<string>("");
  const certificateRef = useRef<HTMLInputElement>(null);
  const avatarRef = useRef<HTMLInputElement>(null);
  let navigate = useNavigate();
  let { currentScreenSize } = useScreenSize();

  useEffect(() => {
    setOptionsSpecialities(
      specialities.map((speciality: Speciality, indexSpeciality: number) => {
        return {
          value: speciality.label.toLowerCase(),
          label: speciality.label,
        };
      })
    );
  }, [specialities]);

  // On récupère les spécialitiés posssibles
  useEffect(() => {
    (async () => {
      let specialitiesResponse: SpecialitiesResponse;
      try {
        let response = await apiReferentials.getSpecialitiesRaw({});
        if (response.raw.status === 200) {
          specialitiesResponse = await response.raw.json();
          setSpecialities(specialitiesResponse.data);
        }
      } catch (error) {
        console.error(error);
      }
    })();
  }, []);

  // L'utilisateur soumet ses informations
  const formSubmit = async (event: any) => {
    event.preventDefault();
    setIsProcessing(true);
    let proPayload: ProfessionalInput = {
      status: status !== "autre" ? status : otherStatut,
      voice_usage_accepted: acceptVoiceUsage,
      phone: phone,
    };

    // Ajout des éléménts facultatifs
    if (avatarID) proPayload = { ...proPayload, avatar: avatarID };
    if (status === "externe" || status === "interne")
      proPayload = { ...proPayload, year_cycle: selectedYear };
    if (status === "docteur" || status === "interne")
      proPayload = { ...proPayload, speciality: selectedSpeciality };
    if (schoolCertificateID)
      proPayload = { ...proPayload, school_certificate: schoolCertificateID };

    // Mis à jour du user
    const accessToken = localStorage.getItem("accessJWT");
    if (accessToken) {
      let decodedAccessJWT: AccessToken = jwtDecode(accessToken);
      let response = await apiUsers.userPatchRaw({
        id: decodedAccessJWT.sub,
        userPartialInput: {
          first_name: firstName,
          last_name: lastName,
          birth_date: birthDate,
        },
      });
      if (response?.raw.status === 200) {
        let professionalsResponse: ProfessionalsResponse;
        // Création du professional
        let response2 = await apiAuth.authProfessionalCreateRaw({
          professionalInput: proPayload,
        });
        if (response2.raw.status === 201) {
          professionalsResponse = await response2.raw.json();
          if (professionalsResponse.data.length === 1) {
            navigate("/choose-your-app");
          } else error();
        } else error();
      } else error();
    }
  };

  const error = () => {
    resetForm();
    notification.error({
      message: "Erreur lors de la validation",
      description: "Veuillez ré-essayer ou contacter le support technique.",
    });
  };

  const handleCertificateChange = async (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    event.preventDefault();
    if (event.target.files?.[0]) {
      let postFileResponse: PostFileResponse;
      // let response = await apiStorages.createFileRaw({
      //   body: event.target.files?.[0],
      // });
      let response = await postFile(
        process.env.REACT_APP_URL_BACKAPP + "/storages/files",
        event.target.files?.[0]
      );
      if (response.status === 201) {
        postFileResponse = await response.json();
        setSchoolCertificateID(postFileResponse.data[0].id);
        let response2 = await apiStorages.getFileRaw({
          id: postFileResponse.data[0].id,
        });
        if (response2.raw.status !== 200)
          notification.error({
            message: "Erreur lors de l'envoi",
            description: "Le fichier n'a pas pu être traité.",
          });
      }
    }
  };

  const postFile = async (url: string, file: File) => {
    const accessToken = localStorage.getItem("accessJWT");
    const refreshToken = localStorage.getItem("refreshJWT");
    return await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/octet-stream",
        Authorization: `Bearer ${accessToken}`,
        "X-Refresh-Token": `${refreshToken}`,
      },
      body: file,
    });
  };

  const handleAvatarChange = async (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    if (event.target.files?.[0]) {
      let postFileResponse: PostFileResponse;
      // let response = await apiStorages.createFileRaw({
      //   body: event.target.files?.[0],
      // });
      let response = await postFile(
        process.env.REACT_APP_URL_BACKAPP + "/storages/files",
        event.target.files?.[0]
      );
      if (response.status === 201) {
        postFileResponse = await response.json();
        setAvatarID(postFileResponse.data[0].id);
        let getFileResponse: any;
        let response2 = await apiStorages.getFileRaw({
          id: postFileResponse.data[0].id,
        });
        if (response2.raw.status === 200) {
          getFileResponse = await response2.raw.blob();
          setAvatar(URL.createObjectURL(getFileResponse));
        } else
          notification.error({
            message: "Erreur",
            description: "L'avatar n'a pas pu être traité.",
          });
      }
    }
  };

  const resetForm = () => {
    setCanSubmit(true);
    setIsProcessing(false);
    setFirstName("");
    setLastName("");
    setBirthDate("");
    setAvatar("");
    setOtherStatut("");
    setSpecialities([]);
    setselectedYear(1);
    setStatus("l2l3");
  };

  const onChangeBirthDate: DatePickerProps["onChange"] = (date, dateString) => {
    if (dateString) setBirthDate(dateString);
  };

  // A chaque changement d'un champs, on check si le formulaire peut être validé ou non
  useEffect(() => {
    if (birthDate && firstName && lastName && phone) {
      if (status === "l2l3") {
        setCanSubmit(true);
      } else if (status === "externe" && selectedYear !== undefined) {
        setCanSubmit(true);
      } else if (status === "interne" && selectedYear !== undefined) {
        setCanSubmit(true);
      } else if (
        status === "docteur" &&
        selectedSpeciality !== "" &&
        selectedSpeciality !== "none"
      ) {
        setCanSubmit(true);
      } else if (status === "autre" && otherStatut !== "") {
        setCanSubmit(true);
      } else {
        setCanSubmit(false);
      }
    } else setCanSubmit(false);
  }, [
    birthDate,
    status,
    selectedYear,
    selectedSpeciality,
    otherStatut,
    firstName,
    lastName,
    phone,
  ]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        paddingRight: "10%",
        paddingLeft: "10%",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          alignItems: "center",
        }}
      >
        <Title level={1}>Renseignez vos informations</Title>
        <input
          type="file"
          name="photo"
          onChange={handleAvatarChange}
          ref={avatarRef}
          style={{ display: "none" }}
          data-cy={"action-avatar"}
        />

        <div
          onClick={() => {
            avatarRef.current?.click();
          }}
          style={{
            textAlign: "center",
            marginBottom: 10,
            cursor: "pointer",
          }}
        >
          <div style={titleStyle}>Votre avatar</div>
          <Avatar
            src={avatar}
            size={64}
            icon={avatar ? null : <UserOutlined />}
            style={{
              position: "relative",

              cursor: "pointer",
              marginBottom: 10,
            }}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 10,
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              gap: 10,
            }}
          >
            <div style={{ flex: 1 }}>
              <div style={titleStyle}>Votre prénom</div>
              <Input
                data-cy={"action-firstname"}
                required
                placeholder="Prénom"
                value={firstName}
                onChange={(event) => setFirstName(event.target.value)}
              />
            </div>
            <div style={{ flex: 1 }}>
              <div style={titleStyle}>Votre nom</div>
              <Input
                data-cy={"action-lastname"}
                value={lastName}
                required
                placeholder="Nom"
                onChange={(event) => setLastName(event.target.value)}
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              gap: 10,
            }}
          >
            <div style={{ flex: 1 }}>
              <div style={titleStyle}>Votre date de naissance</div>
              <DatePicker
                data-cy={"action-birthdate"}
                style={{ width: "100%" }}
                value={birthDate ? dayjs(birthDate, "DD/MM/YYYY") : null}
                format={"DD/MM/YYYY"}
                onChange={onChangeBirthDate}
                placeholder="Date de naissance"
              />
            </div>

            <div style={{ flex: 1 }}>
              <div style={titleStyle}>Votre numéro de téléphone</div>
              <Input
                maxLength={12}
                data-cy={"action-phone"}
                value={phone}
                required
                placeholder="Numéro de téléphone"
                onChange={(event) => setPhone(event.target.value)}
              />
            </div>
          </div>

          <div
            style={{
              display: "flex",
              gap: 10,
            }}
          >
            <div style={{ flex: 1 }}>
              <div style={titleStyle}>Votre statut</div>
              <Select
                data-cy={"action-status-select"}
                value={status}
                onChange={(value) => setStatus(value)}
                style={selectStyle}
                options={[
                  { value: "l2l3", label: "L2/L3/ Médecine" },
                  { value: "externe", label: "Externe" },
                  { value: "interne", label: "Interne" },
                  { value: "docteur", label: "Docteur" },
                  { value: "autre", label: "Autre" },
                ]}
              />
            </div>

            <div style={{ flex: 1 }}>
              {/* Externe */}
              {status === "externe" ? (
                <div>
                  <div style={titleStyle}>Votre année</div>
                  <Select
                    style={{ width: "100%" }}
                    value={selectedYear}
                    onChange={(value) => setselectedYear(value)}
                    options={[
                      { value: 1, label: "1" },
                      { value: 2, label: "2" },
                      { value: 3, label: "3" },
                    ]}
                  />
                </div>
              ) : null}
            </div>
          </div>

          {/* Interne */}
          {status === "interne" ? (
            <div style={{ display: "flex", gap: 10 }}>
              <div style={{ flex: 1 }}>
                <div style={titleStyle}>Votre spécialité</div>
                <Select
                  showSearch
                  allowClear
                  style={selectStyle}
                  onChange={(value) => setSelectedSpeciality(value)}
                  placeholder="Rechercher"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.value ?? "").includes(input)
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.value ?? "")
                      .toLowerCase()
                      .localeCompare((optionB?.value ?? "").toLowerCase())
                  }
                  options={optionsSpecialities}
                ></Select>
              </div>
              <div style={{ flex: 1 }}>
                <div style={titleStyle}>Votre année</div>
                <Select
                  style={selectStyle}
                  value={selectedYear}
                  onChange={(value) => setselectedYear(value)}
                  options={[
                    { value: 1, label: "1" },
                    { value: 2, label: "2" },
                    { value: 3, label: "3" },
                    { value: 4, label: "4" },
                    { value: 5, label: "5" },
                    { value: 6, label: "6" },
                  ]}
                />
              </div>
            </div>
          ) : null}
          {/* Autre */}
          {status === "autre" ? (
            <div>
              <div style={titleStyle}>Décrivez votre statut ici</div>
              <Input
                data-cy={"action-status-text"}
                placeholder="Statut"
                onChange={(event) => setOtherStatut(event.target.value)}
              />
            </div>
          ) : null}
          {/* Docteur */}
          {status === "docteur" ? (
            <div>
              <div style={titleStyle}>Votre spécialité</div>
              <Select
                showSearch
                allowClear
                style={selectStyle}
                onChange={(value) => setSelectedSpeciality(value)}
                placeholder="Rechercher"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.value ?? "").includes(input)
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.value ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.value ?? "").toLowerCase())
                }
                options={optionsSpecialities}
              ></Select>
            </div>
          ) : null}
          {/* Interne ou externe */}
          {status === "interne" || status === "externe" ? (
            <div
              style={{
                marginTop: 10,
              }}
            >
              <div style={titleStyle}>Certificat de scolarité (facultatif)</div>
              <div
                style={{
                  display: "flex",
                  flexDirection:
                    currentScreenSize <= breakpoints.lg ? "column" : "row",
                  gap: currentScreenSize <= breakpoints.lg ? 10 : 50,
                  alignItems: "center",
                }}
              >
                <div style={{ flex: 1, fontSize: 12, textAlign: "justify" }}>
                  Thiana offre des applications gratuites pour les internes et
                  externes. Renseignez un certificat de scolarité pour profiter
                  de Thiana gratuitement (si vous n’en avez pas sous la main,
                  vous pourrez le faire une fois dans l’application)
                </div>
                <div style={{ flex: 1 }}>
                  {schoolCertificateID === "" ? (
                    <Button
                      icon={<UploadOutlined />}
                      onClick={() =>
                        schoolCertificateID === ""
                          ? certificateRef.current?.click()
                          : null
                      }
                    >
                      Ajouter un certificat de scolarité
                    </Button>
                  ) : (
                    <div
                      style={{
                        padding: 10,
                        color: colors.thiana.success[500],
                        fontSize: 12,
                      }}
                    >
                      Certificat envoyé avec succès <CheckCircleFilled />
                    </div>
                  )}

                  <input
                    type="file"
                    name="photo"
                    onChange={handleCertificateChange}
                    ref={certificateRef}
                    style={{ display: "none" }}
                    multiple={false}
                  />
                </div>
              </div>
            </div>
          ) : null}
        </div>

        <div style={{ marginTop: 20 }}>
          <div style={titleStyle}>
            Utilisation des données audios (facultatif)
          </div>
          <div
            style={{
              display: "flex",
              gap: 5,
              alignItems: "center",
              justifyContent: "right",
            }}
          >
            <Checkbox
              data-cy={"action-acceptConsentVoice"}
              checked={acceptVoiceUsage}
              onChange={(e) => setAcceptVoiceUsage(e.target.checked)}
            />

            <div style={{ fontSize: 12 }}>
              En cochant cette case, vous acceptez que vos données audios
              puissent être utilisées pour améliorer la reconnaissance vocale de
              Thiana.
            </div>
            {/* </div> */}
          </div>
        </div>
        <Button
          type={"primary"}
          disabled={!canSubmit || isProcessing}
          onClick={formSubmit}
          style={{
            marginTop: 20,
            width: "100%",
            cursor: canSubmit && !isProcessing ? "pointer" : "auto",
          }}
        >
          {isProcessing ? "En cours ..." : "Valider mes informations"}
        </Button>
      </div>

      <div style={{ textAlign: "center" }}>
        <Button
          type="text"
          onClick={() => navigate("/logout")}
          style={{
            color: colors.thiana.grey[500],
            marginTop: 20,
          }}
        >
          Déconnexion
        </Button>
      </div>
    </div>
  );
}
