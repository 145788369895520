import { notification } from "antd";
import { AccessToken } from "../types/token";
import jwt_decode from "jwt-decode";
import { useNavigate } from "react-router-dom";
import { apiAuth } from "../api/api-thiana-client/Configuration";

export default function useLogin() {
  let navigate = useNavigate();

  const login = async (email: string, password: string) => {
    let decodedAccessToken: AccessToken;
    let response = await apiAuth.authUserLoginRaw({
      loginInput: { email: email, password: password },
    });
    if (response.raw.status === 200) {
      let accessToken = response.raw.headers.get("X-Access-Token");
      if (accessToken) {
        decodedAccessToken = jwt_decode(accessToken);
        // Si le compte est activé
        if (decodedAccessToken.act) {
          // Si le professionel est déjà créé (le professionel est crée lors du postProfessional lors du submitWizard)
          if (decodedAccessToken.pro) {
            navigate("/choose-your-app");
            notification.success({
              message: "Connexion",
              description: "Vous êtes connecté(e).",
            });
          }
          // Si le professionel n'est pas encore crée
          else navigate("/informations");
        } else {
          navigate("/activate");
          notification.error({
            message: "Compte inactif.",
            description: "Ce compte n'est pas encore activé.",
          });
        }
      }
    }
  };

  return { login };
}
